/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    strong: "strong"
  }, _provideComponents(), props.components), {StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "itchy-ears-everything-you-need-to-know",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#itchy-ears-everything-you-need-to-know",
    "aria-label": "itchy ears everything you need to know permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Itchy Ears: Everything You Need to Know"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Itchy ears can be a serious annoyance. Not only is it uncomfortable, but it’s also in a place you cannot scratch. There are many different types and causes of ear itchiness ranging from harmless to severe. People can experience itching in the outer ear, the ear canal or the eardrum, and the itching can be momentary or long-lasting."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear itchiness has many different causes, but the issue that causes it is usually the same: skin inflammation. When the skin of the ear becomes irritated, it creates the familiar itching sensation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Continue reading to find the answers to all of your itchy ear questions, including:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Why do my ears itch?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "What should I do when my ears itch?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "How do I get relief from ear itchiness?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "How do I prevent ear itching?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "What should I avoid when my ears itch?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "When should I see a doctor about my itchy ears?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Does my child have itchy ears?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are itchy ears a symptom of an ear infection?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are itchy ears a symptom of COVID-19?"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-do-my-ears-itch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-my-ears-itch",
    "aria-label": "why do my ears itch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do my ears itch?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those suffering from itchy ears, often the first question asked is, “Why?” In the case of itchy ears, however, one symptom can have many possible causes. Below are some common and not-so-common conditions that cause ears to itch:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "allergies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#allergies",
    "aria-label": "allergies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Allergies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Allergic reactions are among the most common causes of ear itchiness. Skin allergies, nasal allergies and even food allergies can result in itching ears."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "skin-allergies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#skin-allergies",
    "aria-label": "skin allergies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Skin Allergies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Skin Allergies — also known as contact dermatitis — occur when the skin comes in contact with an item or substance it is sensitive to, causing the skin to become inflamed and itchy. Skin allergies often also include a rash and redness of the skin."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If a skin allergy triggered your ear itch, the way to stop the itching is to remove the irritant. Discontinue use of any products to which you are allergic."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "nasal-allergies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nasal-allergies",
    "aria-label": "nasal allergies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Nasal Allergies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It may seem strange that nasal allergies can cause ear itching, but this is quite common. Because the ear, nose and throat are all connected, allergic irritation in the nose can spread to the ears and throat as well."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Common nasal allergies that can lead to ear itchiness include allergic rhinitis (also called hay fever), pollen, mold, dust mites, pet dander and smoke. Nasal allergies are more common in people with asthma."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear itch caused by nasal allergies is best treated by avoiding the allergy trigger if possible. Keep carpets vacuumed and change your bedding often. Over-the-counter oral antihistamines or nasal allergy sprays can be effective in relieving itchy ears as well."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "food-allergies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#food-allergies",
    "aria-label": "food allergies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Food Allergies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear itching is a common symptom of an allergic reaction to food. If a food reaction causes ear itching, it will usually include itchiness in the throat as well. The irritation to the throat often spreads to the ears, causing discomfort."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Like nasal allergies, ear itch caused by food allergies can be alleviated by avoiding the food causing the reaction and taking an over-the-counter antihistamine for immediate relief."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "NOTE: If the allergic reaction includes swelling or constriction of the throat or windpipe, seek medical attention."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "over-cleaning-using-cotton-swabs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#over-cleaning-using-cotton-swabs",
    "aria-label": "over cleaning using cotton swabs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Over Cleaning (Using Cotton Swabs)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most of us have heard that cotton swabs should never be used to clean our ears. There are two major reasons for this warning: swabs can remove too much protective wax, and they  also can irritate and damage the delicate skin of the ear canal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Contrary to popular belief, ear wax is a healthy and essential part of ear well-being. The wax provides a protective layer that keeps out bacteria, catches and removes foreign particles, and keeps the ear canal moisturized. When ears are over-cleaned, all of those benefits are lost, resulting in dry ears that are more prone to itchy skin infections."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cotton swabs also cause physical damage to the skin inside the ear. When the skin is damaged and inflamed, it can become infected and result in redness and itching."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The solution to ear itching caused by using swabs is obvious: stop doing it. Normal, healthy ears don’t need internal cleaning, as the wax pushes itself outside of the ear as more is formed. Instead of using cotton swabs, gently clean the wax outside the ear after being in the warm shower. Contact your doctor if you feel that you have excessive ear wax (e.g., if you believe wax is plugging your ears and causing muffled hearing). They can safely remove excess wax for you in the office."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "eczema-and-psoriasis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#eczema-and-psoriasis",
    "aria-label": "eczema and psoriasis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Eczema and Psoriasis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The common skin conditions eczema and psoriasis can cause itching of the outside and inside of the ear."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "eczema",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#eczema",
    "aria-label": "eczema permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Eczema"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This refers to a range of common skin conditions, including allergic skin reactions. Ear eczema is most commonly caused by seborrheic dermatitis (also known as dandruff). Seborrheic dermatitis is a type of inflammation that affects the oil-producing sebaceous glands in the skin. As such, it is most often found in areas with a high concentration of these glands, including the scalp, eyebrows, creases of the nose and ears. When the condition affects the ears, it can affect the outer ear, ear canal, eardrum and the skin behind the ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Seborrheic dermatitis is usually diagnosed with a visual inspection; the area will have greasy-looking white or yellowish scales and may be slightly red. If you have itchy ears caused by seborrheic dermatitis, the doctor may prescribe a topical ointment, and keeping the area moisturized may help alleviate itching. If the itch is inside the ear canal, ask your doctor whether it would be safe to put a couple of drops of oil in your ears to keep them from drying out."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "psoriasis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#psoriasis",
    "aria-label": "psoriasis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Psoriasis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Psoriasis is an autoimmune skin condition that causes the skin to form red patches covered with silvery scales or plaques. While more common in other parts of the body, psoriasis can occur outside and inside the ear. Scratching or trying to remove the plaques can lead to bleeding and cause the condition to worsen or spread. If you think you might have psoriasis of the ears, see a doctor. They can prescribe steroid drops to help soothe the itching and inflammation."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "outer-ear-infection-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#outer-ear-infection-swimmers-ear",
    "aria-label": "outer ear infection swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Outer Ear Infection (Swimmer’s Ear)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Infection of the outer ear (commonly known as swimmer’s ear) can cause moderate to severe itching of the outside of the ear and the ear canal. Swimmer’s ear is caused by an infection (usually bacterial) that creates inflammation, redness, drainage, pain when touching the ear and a feeling of fullness in the ear or muffled hearing. The infection is usually caused by water in the ear that is not immediately removed/dried, allowing bacteria to grow."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you experience any pain in your ear, contact your doctor. The treatment for swimmer’s ear is typically prescription antibiotic drops. Depending on the severity of the infection, your doctor may also prescribe steroid drops or remove debris from the infection during your visit to help the drops work and relieve some itching and discomfort."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "dirty-earbuds",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dirty-earbuds",
    "aria-label": "dirty earbuds permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dirty Earbuds"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because earbuds and hearing aid earmolds are worn in the ear, they can damage the delicate skin and cause infection and itching. Be sure to clean the items thoroughly and regularly to reduce the chances of an infection. Itching may also occur if the earbuds do not fit correctly or if you have or develop an allergy to the device’s material (typically rubber or plastic). If you wear a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " and are experiencing itching, contact your hearing specialist."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "common-cold",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-cold",
    "aria-label": "common cold permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common Cold"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sometimes a cold or flu can cause sinus infections which can impact the ears. If you have a cold and have developed itching ears, an over-the-counter decongestant may help."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "temporomandibular-joint-disorders-tmjtmd",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#temporomandibular-joint-disorders-tmjtmd",
    "aria-label": "temporomandibular joint disorders tmjtmd permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Temporomandibular Joint Disorders (TMJ/TMD)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Temporomandibular Joint Disorders (TMD) are disorders of the jaw that can impact other parts of the face. TMD can cause clenched teeth, jaw locking or popping, dizziness and pain behind the eyes or in the shoulders, neck and back. TMD can also cause pain and ringing in the ears and occasionally cause the ears to itch."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think you may have TMD, see a doctor for an examination. They may order diagnostic imaging like x-rays or an MRI. Treatment for TMD ranges anywhere from ice packs to surgery, depending on the seriousness of the case."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-i-get-relief-from-ear-itchiness",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-i-get-relief-from-ear-itchiness",
    "aria-label": "how do i get relief from ear itchiness permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do I get relief from ear itchiness?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Relieving itching ears depends on what is causing them to itch. Some possible remedies include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Antihistamines."), " These will help if the itching is due to an allergic reaction. You can use oral antihistamines or, for nasal allergies, nasal sprays. Topical creams can be applied gently to itchy areas of the outer ear, but never put a cream in the ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Steroid drops."), " If your ear itching was accompanied by significant swelling, a doctor might prescribe steroid drops to reduce the inflammation, which may also provide some relief."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Antibiotic drops and antifungal drops."), " Your doctor may prescribe drops to kill the bacteria/fungus in bacterial or fungal infections. As the drops begin to work, you should notice a reduction in itching."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Moisturizing the ears."), " If the itching is on the outside of the ear, applying a gentle moisturizer will reduce dryness and may help. Speak with your doctor about putting drops of oil in your ears to relieve dryness and itching in the ear canal. If appropriate, your doctor will tell you what kind of oil to use and how frequently to apply it."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Drops to break up earwax."), " If the itching in the ear is caused by excess wax inside the ear, you may be able to use over-the-counter drops to break up the wax. Diligently follow the directions for the drops; after application, let the drops sit, then drain onto a clean towel. Do not put any foreign objects inside your ear canal. If this method is unsuccessful, see a doctor to have the wax removed."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-should-i-avoid-when-my-ears-itch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-should-i-avoid-when-my-ears-itch",
    "aria-label": "what should i avoid when my ears itch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What should I avoid when my ears itch?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When suffering from itchy ears, be sure to avoid these remedies, activities and situations:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Scratching the ear with fingernails, cotton swabs or any other object."), " It is tempting to try to scratch, but scratching can do serious damage to the ear and make any underlying infection much worse. Also, scratching an itch stimulates itch-sensing nerves to grow, making it itch more over time."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Getting water in the ear."), " Be extra careful when bathing, swimming or washing your hair to avoid getting water into your itchy ears. Again, the reason is infection: fungus and bacteria love to grow in wet places."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Smoke."), " There is some evidence that smoke can cause allergic irritation in the ears. If your ears are already itchy, avoid smoke (cigarette, barbecue, etc.) if possible."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-i-prevent-ear-itching",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-i-prevent-ear-itching",
    "aria-label": "how do i prevent ear itching permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do I prevent ear itching?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Since the most common cause of itchy inflamed ears is an infection, prevention means lowering your chance to get infections of the ear in the first place."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Never stick anything in the ear canal — toothpicks, bobby pins, pencils, ear candles, twisted cloth, keys or other objects. The environment inside the ear is a delicate balance maintained by your body. Inserting objects into the ear upsets that balance and removes protective, water-repellent earwax and causes tears in the thin skin of the ear canal. The outcome is ears primed for infection and itching."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "when-should-i-see-a-doctor-about-itchy-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-should-i-see-a-doctor-about-itchy-ears",
    "aria-label": "when should i see a doctor about itchy ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When Should I See a Doctor About Itchy Ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you experience persistent itching in your ears that is not due to a known allergy, you should consult a doctor. Always see a doctor if you’re in any pain or experience a ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "loss of hearing"), ". You can visit either your primary care physician or an ear, nose, and throat specialist (ENT) for an examination."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-itchy-ears-a-symptom-of-an-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-itchy-ears-a-symptom-of-an-ear-infection",
    "aria-label": "are itchy ears a symptom of an ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are itchy ears a symptom of an ear infection?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Itching ears are rarely a sign of a middle ear infection (an infection of the area behind the eardrum; middle ear infections are the type of ear infection most people mean when they say “ear infection”). If you or your child’s ear is itching, you should not assume there is an ear infection. Instead, monitor the situation at home. If pain develops or the problem has not cleared within 48 hours, then consult with a doctor."), "\n", React.createElement(LandingPageCta, {
    copy: "I'M READY TO FIX MY ITCHY EARS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
